import React, { useState, useEffect } from "react"; 
import blogpng from "../assets/blogpng.png";
import { FaArrowRight } from "react-icons/fa";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import AOS from "aos";
import "aos/dist/aos.css";
import { BsCalendar2DateFill } from "react-icons/bs";
import Blog1 from "../assets/BLOG1.jpg";
import Blog2 from "../assets/BLOG2.png";
import Blog3 from "../assets/BLOG3.jpg";
import Blog4 from "../assets/BLOG4.jpg";
import Blog5 from "../assets/Blog.jpeg"
import Blog6 from "../assets/BLOG5.jpeg"
import Blog7 from "../assets/Wineblog.jpg"
import Blog8 from "../assets/Robotics-for-Beginners.jpg"
import Blog9 from "../assets/Web-Server.png"
import Blog10 from "../assets/Wifi.png"
import Blog11 from "../assets/PythonBlog.png"
import "../BlogMain/BlogMain.css";

function BlogMain() {
    useEffect(() => {
        // Initialize AOS with a non-zero duration
        AOS.init({ duration: 1000 });
    }, []);

    const InsightsData = [
        {
            title: "Microcontroller Basics - A Comprehensive Guide for Beginners",
            image: Blog1,
            description: "Unlock the essentials of microcontrollers with this beginner-friendly guide, covering fundamental concepts, applications, and step-by-step instructions for getting started.",
            ref: "https://www.google.com",
            date: "08/06/2024"
        },
        {
            title: "A Beginner's guide for Machine Learning Text Classification using Python",
            image: Blog2,
            description:"Learn the basics of text classification in machine learning with Python, featuring simple explanations, practical examples, and hands-on coding exercises for novices.",
            ref: "https://www.google.com",
            date: "08/06/2024"
        },
        {
            title: "Machine Learning for Dummies: An Absolute Beginner's Guide",
            image: Blog3,
            description:"Demystify machine learning with this easy-to-understand guide, perfect for absolute beginners looking to grasp the core principles and applications of this transformative technology.",
            ref: "https://www.google.com",
            date: "08/06/2024"
        },
        {
            title: "Artificial Intelligence 101: How Does AI Work",
            image: Blog4,
            description:"Explore the foundations of artificial intelligence in this introductory guide, explaining how AI systems function and their impact on various industries and everyday life.",
            ref: "https://www.google.com",
            date: "08/06/2024"
        },
        {
            title: "Regression Modelling using Linear Regression",
            image: Blog5,
            description:"These regression models helped us estimate the house prices using the numeric input features. Moreover, the models were also trained using the more correlated features of the data set",
            ref: "https://www.google.com",
            date: "08/06/2024"
        },
        {
            title: "How to Forecast Sales using Machine Learning",
            image: Blog6,
            description:"Trained models were evaluated using Root Mean Squared Error, Mean Absolute Error, and R2 Score between the predicted sale values and the actual sale values in the test dataset.",
            ref: "https://www.google.com",
            date: "08/06/2024"
        },
        {
            title: "Binary Classification of Wine dataset using Support Vector Machines",
            image: Blog7,
            description:"These classification models helped us identify the Red and White wine types using numeric input features. The hyperparameters of all the models were also tuned to optimize the performance of the classification model for all the algorithms.",
            ref: "https://www.google.com",
            date: "08/06/2024"
        },
        {
            title: "Robotics for Beginners: Basics of Robots Explained Comprehensively",
            image: Blog8,
            description:"To be precise, the knowledge of Robotics is primarily used to MOVE a Robot within the physical space of the humans, which involves designing, building, and programming the robots.",
            ref: "https://www.google.com",
            date: "08/06/2024"
        },
        {
            title:"How to configure ESP8266 Web Server in Station and Access Point Mode",
            image: Blog9,
            description:"This article outlines how you can set up such a simple webserver on ESP8266 and use it to control 4 LEDs in real-time via a web browser in your smartphone, laptop, or tablet.",
            ref: "https://www.google.com",
            date: "08/06/2024"
        },
        {
            title:"How to Configure ESP8266 WiFi in Station (STA), Access Point (AP) and MultiWiFi Mode",
            image:Blog10,
            description:"In this article, I will explain how you can prepare sketches for the ESP8266 WiFi Module to connect to an existing WiFi (the one advertised by your router), or to advertise its WiFi hotspot.",
            ref: "https://www.google.com",
            date: "08/06/2024"
        },
        {
            title:"NodeMCU ESP8266 Micropython Programming: Get Started with esptool and Thonny IDE",
            image: Blog11,
            description:"In this tutorial, I will show you how to program ESP8266 using MicroPython so that you can leverage the power of Python for your next IoT project.",
              ref: "https://www.google.com",
            date: "08/06/2024"

        }
    ];
    
    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 6;
  
    // Calculate the index of the first and last card on the current page
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = InsightsData.slice(indexOfFirstCard, indexOfLastCard);
    const totalCards = InsightsData?.length || 0;
    const pageCount = Math.ceil(totalCards / cardsPerPage);
    // Handle page change
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        // Scroll to the top of the page smoothly after pagination
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    return (
        <>
            <div className="container-fluid bg-light">
                <div className="row pt-5 pb-5 ">
                    <div className="col-lg-6 col-12 blogmain-content">
                        <h1><span style={{color:"#00A79D"}}>Neksogix's </span> Insights & Trends</h1>
                        <button className="blogmain-btn mt-4"><span>How it works</span></button>
                    </div>
                    <div className="col-12 col-lg-6 p-5  blog-img" >
                        <img src={blogpng} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>

            <div className="container-fluid blog-card-container ">
            <div className="text-center content " data-aos="fade-up">
                <h1>Featured Blogs</h1>
                </div>
                <div className="row p-5 pb-0">
                    {currentCards.map((insight, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-12 mb-5" data-aos="fade-up">
                            <div className="p-1 d-flex align-items-center justify-content-center">
                                <div className="card-blog">
                                    <div className="card-image-container-blog">
                                        <img src={insight.image} alt="Card Image" className="card-image-blog w-100" />
                                    </div>
                                    <div className="card-content-blog">
                                        <div className="card-date">
                                            <i><BsCalendar2DateFill /></i> <span>{insight.date}</span>
                                        </div>
                                        <h2 className="card-title-blog">{insight.title}</h2>
                                        <p className="card-description-blog">{insight.description}</p>
                                        <a href={insight.ref} className="card-button-blog">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="custom-pagination">
      <Stack spacing={2}>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handlePageChange}
          size="large"       />
      </Stack>
    </div>
                </div>
               
        </>
    );
}

export default BlogMain;
