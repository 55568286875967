import React, { useEffect, useState} from "react";
import { FaArrowRight } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import "../Project/Project.css";

function Project() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);


    // Example data array
    const projectItems = [
        {
            title: "Advanced PDF Data Extraction",
            description: "Our experience in data processing transforms complex PDF data into organized CSV files. Using Python, Pandas, PDFPlumber, and regular expressions, we ensure precise extraction and structuring.",
            link: "#"
        },
        {
            title: "Customized Chatbot Solution",
            description: "Our NLP expertise powers our Customized Chatbot Solution for educational support. Enhance student engagement and administrative efficiency with our advanced chatbot.",
            link: "#"
        },
        {
            title: "Information Scrapping System",
            description: "Our Web scrapping experience optimizes e-commerce operations with our Information scrapping System. Using Python, Selenium, and tkinter, we automate accurate product data extraction.",
            link: "#"
        }
    ];
    

    return (
        <>
            <div className="container-fluid bg-light project-container pt-5 " >
            <div 
                    className="background-image-container" 
                    data-aos="slide-right"
                ></div>
                <div className="row p-5 d-lg-flex align-items-lg-center">
                    <div className="col-lg-6 col-12 pb-5 p-lg-5" data-aos="fade-up">
                    <h2 className="project-text text-wrap ">
            Discover our innovative projects that showcase our expertise in cutting-edge technology solutions. Explore our work to see how we can help you turn your ideas into reality.
          </h2>
                        <div className="project-more-container pt-3">
                            <a className="project-more project-btn" href="#" target="_blank">
                                Explore Our Projects
                                <FaArrowRight className="arrow-icon-project" />
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-6 col-12 p-lg-5">
                        <ul className="ps-0">
                            {projectItems.map((item, index) => (
                                <li
                                    key={index}
                                    style={{ listStyle: "none" }}
                                    className="pb-4"
                                    data-aos="fade-up"
                                    data-aos-delay={index * 100}
                                >
                                    <div className="list-container">
                                        <h4 className="pt-3">{item.title}</h4>
                                        <p>{item.description}</p>
                                        <div className="project-more-container pb-3">
                                            <a className="learn-project-more learn-project-btn" href={item.link} target="_blank">
                                                Learn More
                                                <FaArrowRight className="arrow-icon" />
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default Project;
