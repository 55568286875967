import React, { useState,useEffect } from 'react';
import { IoSearch } from "react-icons/io5";
import logo from "../assets/LogowithoutBG.svg";
import {
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure
} from "@chakra-ui/react";
import './Navbar.css';

function Navbaar() {
  const [searchOpen, setSearchOpen] = useState(false);

    const toggleSearch = () => {
        setSearchOpen(!searchOpen);
    };
  const [isHovered, setIsHovered] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
 <>
      <nav className="navbar navbar-expand-lg sticky-top "  >
        <div className="container-fluid pe-0 ps-0">
          <div className='d-flex flex-row align-items-center' >
            <a className="navbar-brand me-0 ms-2 ms-md-0 " href="#">
              <img src={logo} width={70} alt="Logo" />
            </a>
            <div className='title'>
              <h2 className='mb-0 ' >NEKSOGIX</h2>
            </div>
          </div>
          <button className="navbar-toggler" type="button" onClick={onOpen}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center ms-lg-4 me-lg-4  " id="navbarSupportedContent">
            <ul className={`navbar-nav d-none d-lg-flex ${searchOpen ? 'shrink' : ''}`}>
              
              <li className="nav-item dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className="d-flex align-items-center ">
                  <a className={`nav-link ${isHovered ? 'hover-color' : ''}`} data-bs-toggle="dropdown" aria-expanded="false" href="#">
                    SERVICES
                  </a>
                  <a type="button" className={`dropdown-toggle ${isHovered ? 'hover-color' : ''}`} data-bs-toggle="dropdown" aria-expanded="false"></a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Web Development</a></li>
                    <li><a className="dropdown-item" href="#">Mobile App Development</a></li>
                    <li><a className="dropdown-item" href="#">ECommerce Store</a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">INSIGHTS</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">BLOGS</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#">INDUSTRIES</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">CONTACT US</a>
              </li>
            </ul>
          </div>
          <div className='collapse  d-lg-flex justify-content-lg-center'>
            <div className={`search-container ${searchOpen ? 'expanded' : ''}`}>
                <i className='search-icon pe-2' onClick={toggleSearch}><IoSearch /></i>
                <input 
                    type="text" 
                    className="search-input me-3 ms-0" 
                    placeholder="Search..." 
                    onBlur={() => setSearchOpen(false)} 
                />
            </div>
            <button className="login-btn me-3 ">
                <span>Login</span>
            </button>
        </div>
        </div>
      </nav>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <ul className="navbar-nav" style={{marginTop:40}}>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#">INDUSTRIES</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">INSIGHTS</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">SERVICES</a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="#">Web Development</a></li>
                  <li><a className="dropdown-item" href="#">Mobile App Development</a></li>
                  <li><a className="dropdown-item" href="#">ECommerce Store</a></li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">BLOGS</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">CONTACT US</a>
              </li>
            </ul>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      </>
  );
}

export default Navbaar;
