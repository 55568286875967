import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import Blog1 from "../assets/BLOG1.jpg";
import Blog2 from "../assets/BLOG2.png";
import Blog3 from "../assets/BLOG3.jpg";
import Blog4 from "../assets/BLOG4.jpg";
import "../Insights/Insights.css";

function Insights() {
    useEffect(() => {
        // Initialize AOS with a non-zero duration
        AOS.init({ duration: 1000 });
    }, []);
    window.scrollTo({ top: 0, behavior: 'smooth' })

    const [InsightsData] = useState([
        {
            title: "Microcontroller Basics - A Comprehensive Guide for Beginners",
            image: Blog1,
            description: "Unlock the essentials of microcontrollers with this beginner-friendly guide, covering fundamental concepts, applications, and step-by-step instructions for getting started.",
            to: "http://localhost:3000/blog"
        },
        {
            title: "A Beginner's guide for Machine Learning Text Classification using Python",
            image: Blog2,
            description:"Learn the basics of text classification in machine learning with Python, featuring simple explanations, practical examples, and hands-on coding exercises for novices.",
            to: "http://localhost:3000/blog"
        },
        {
            title: "Machine Learning for Dummies: An Absolute Beginner's Guide",
            image: Blog3,
            description:"Demystify machine learning with this easy-to-understand guide, perfect for absolute beginners looking to grasp the core principles and applications of this transformative technology.",
            to: "http://localhost:3000/blog"
        },
        {
            title: "Artificial Intelligence 101: How Does AI Work",
            image: Blog4,
            description:"Explore the foundations of artificial intelligence in this introductory guide, explaining how AI systems function and their impact on various industries and everyday life.",
            to: "http://localhost:3000/blog"
        },
    ]);

    return (
        <div className="container-fluid insight-section">
            <div className="text-center insight-content " data-aos="fade-up">
                <h1>Featured Blogs</h1>
                <h5>Discover Insights and Inspiration in Our Featured Blogs!</h5>
            </div>
            <div className="row p-5 pb-0">
                {InsightsData.map((insight, index) => (
                    <div key={index} className="col-md-6 col-sm-12 mb-5" data-aos="fade-up">
                        <div className="card h-100">
                            <div className="image-container">
                                <img src={insight.image} className="card-img-top" alt={insight.title} />
                                <div className="overlay-card">
                                    <div className="text">
                                        <p>{insight.description}</p>
                                        <Link to={insight.to}  className="explore-btn">
                                            Explore Now
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-3">
                                <h5 className="card-title" >{insight.title}</h5>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="text-center pb-5" data-aos="fade-up">
            <div className="explore-more-container"  >
                <Link className="explore-more explore-btn" to={"http://localhost:3000/blog"} target="_blank"  >
                    Explore More
                    <FaArrowRight className="arrow-icon" />
                </Link>
            </div>
            </div>
        </div>
    );
}

export default Insights;
