import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaArrowRight } from 'react-icons/fa';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import "../Services/Services.css";
import service from "../assets/AI_img.gif";
import service1 from "../assets/Animation2.gif";
import service2 from "../assets/Animation3.gif";

function Services() {
  const [servicesData] = useState([
    {
      title: 'Artificial Intelligence',
      description: "Our expertise in artificial intelligence enables us to deliver bespoke solutions that are precisely tailored to your needs. Our cutting-edge AI solutions are designed to enhance your company's capabilities and optimize its performance in today's highly competitive market.",
      image: service,
    },
    {
      title: 'Web Development',
      description: "Our web development expertise transforms your digital vision into captivating, high-performing websites that drive business growth and engagement in today's competitive landscape and optimize its performance in today's highly competitive market hhhdhhdhdhdhdhdhdhdhdhdhhdhd..",
      image: service1,
    },
    {
      title: 'Data Analysis',
      description: "Our proficiency in data analysis empowers us to provide customized solutions that meet your specific requirements, delivering insights that enhance decision-making and optimize performance in today's competitive landscape and optimize its performance in today's highly competitive market.",
      image: service2,
    },
  ]);

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      navigation={true}
      modules={[Pagination, Navigation , Autoplay]}
      className="mySwiper container-fluid service-container"
    >
      {servicesData.map((service, index) => (
        <SwiperSlide key={index}>
          <div className='row d-flex justify-content-center align-items-center pt-5 pb-5' >
            {index % 2 === 0 ? (
              <>
                <div className='col-12 col-md-7 col-lg-6 p-5  service-content text-center text-lg-start'>
                  <div className='d-flex flex-column justify-content-center services-text align-items-lg-start text-wrap ps-lg-5'>
                    <h1>{service.title}</h1>
                    <p>{service.description}</p>
                    <div className='learn-more-container'>
                    <a className="learn-more learn-btn" href="#" target="_blank">
                      Learn More
                      <FaArrowRight className="arrow-icon-btn" />
                    </a></div>
                  </div>
                </div>
                <div className='col-12 col-md-5 col-lg-6 d-flex justify-content-center align-items-center  service-img'>
                  <img src={service.image} alt="" className='img-fluid' />
                </div>
              </>
            ) : (
              <>
                <div className='col-12 col-md-5 col-lg-6 d-flex justify-content-center align-items-center service-img'>
                  <img src={service.image} alt="" className='img-fluid' />
                </div>
                <div className='col-12 col-md-7 col-lg-6 p-5 service-content text-center text-lg-start'>
                  <div className='d-flex flex-column justify-content-center align-items-lg-start text-wrap ps-lg-5 '>
                    <h1>{service.title}</h1>
                    <p>{service.description}</p>
                    <div className='learn-more-container'>
                    <a className="learn-more learn-btn" href="#" target="_blank">
                      Learn More
                      <FaArrowRight className="arrow-icon-btn" />
                    </a></div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className=" explore-more-services-container">
            <a className="explore-more-services exp-btn mb-5" href="#" target="_blank">
              Explore More Services
              <FaArrowRight className="arrow-icon" />
            </a>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Services;
