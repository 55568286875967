import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom"
import './index.css';
import App from './App';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { ChakraProvider } from '@chakra-ui/react'

const muiTheme = createTheme({
  // Customize your MUI theme here
});

ReactDOM.render(
  <ChakraProvider>
    <MUIThemeProvider theme={muiTheme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MUIThemeProvider>
  </ChakraProvider>,
  document.getElementById('root')
);

