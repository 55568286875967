import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from "./Navbar/Navbar"
import Home from './Home/Home'
import Footer from "./Footer/Footer"
import BlogMain from "./BlogMain//BlogMain"
function App() {
  return (
    <>
    <Navbar/>
    <Routes>
    <Route index element={<Home/>} ></Route>
    <Route path="/home" element={<Home/>} ></Route>
    <Route path="/blog" element={<BlogMain/>} ></Route>
    </Routes>
    <Footer/>
    </>
  );
}

export default App;
