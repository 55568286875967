import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import logo from "../assets/AmblemwithBG.svg";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { IoIosMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { IoStarSharp } from "react-icons/io5";

import "./Footer.css";

function Footer() {
  const scheduling = 4.5;
  const cost = 4.5;
  const quality = 4.5;
  const recomend = 5;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
    };

    emailjs
      .send(
        "service_m2uup3n",
        "template_ixid3ja",
        templateParams,
        "hcjiTwU5_KXfI9gMO"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          alert("Email sent successfully!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("Failed to send email. Please try again later.");
        }
      );
  };

  return (
    <>
      <div className="container-fluid footer-area">
        <div className="row pt-5 pb-5  ">
          <div className="col-lg-8 col-12 p-5 pt-3">
            <div className="d-flex flex-column align-items-center align-items-lg-start footer-content p-5 text-center text-lg-start">
              <h1>Schedule a free consultation with one of our experts.</h1>
              <h5>
                Take the first step towards a brighter future and supercharge
                your business with cutting-edge technologies, expert guidance,
                and unparalleled support, driving unparalleled success and
                growth, ensuring your competitive edge in the market.
              </h5>
              <button className="footer-btn">
                <span>Schedule Now</span>
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-12 p-3 contact-content">
            <div className="contact-form">
              <h1 className="text-center">Contact Us</h1>
              <form ref={form} onSubmit={sendEmail}>
                <div className="m-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="m-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="m-3">
                  <label className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    rows="1"
                    placeholder="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="button-container">
                  <button type="submit" className="m-3 submit-btn">
                    <span>Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row pt-5 ">
          <div className="col-lg-4 col-md-6 col-sm-6 ps-lg-5 pe-5 d-flex flex-column align-items-start">
            <div className="d-flex align-items-start">
              <img src={logo} width={100} className="m-2" alt="Neksogix Logo" />
              <p className="ms-2 mt-2 text-white">
                <span className="fw-bold">Neksogix:</span> Your go-to experts in
                Python, machine learning, and AI for groundbreaking digital
                transformation and growth.
              </p>
            </div>

            <div className="pt-4">
              <ul
                style={{ listStyle: "none", color: "white", paddingLeft: "0" }}
              >
                <li className="d-flex align-items-center mb-2">
                  <IoIosCall style={{ fontSize: "30px" }} />
                  <p className="mb-0">0304 4181616</p>
                </li>
                <li className="d-flex align-items-center mb-2">
                  <IoIosMail style={{ fontSize: "30px" }} />
                  <p className="mb-0">neksogix_solutions@neksogix.com</p>
                </li>
                <li className="d-flex align-items-center">
                  <IoLocationSharp style={{ fontSize: "30px" }} />
                  <p className="mb-0">Austin, Texas</p>
                </li>
              </ul>
            </div>

            <div
              className="pt-4 d-flex align-items-center gap-3 pb-5 "
              style={{ color: "white" }}
            >
               <FaFacebook size={30} className="fa-fb fa-icons" />
      <FaTwitter size={30} className="fa-twitter fa-icons" />
      <FaInstagram size={30} className="fa-instagram fa-icons" />
      <FaLinkedin size={30} className="fa-linkedin fa-icons" />
            </div>
          </div>

          <div className="col-lg-2 col-md-6 col-sm-6 d-md-flex justify-content-md-center d-sm-flex justify-content-sm-center pt-3 ps-0 text-white">
  <ul style={{ listStyle: "none" }}>
    <li className="fw-bold pb-2">Use Cases</li>
    <li><a href="#ui-design" className="footer-link">UI Design</a></li>
    <li><a href="#wireframing" className="footer-link">Wireframing</a></li>
    <li><a href="#diagramming" className="footer-link">Diagramming</a></li>
    <li><a href="#brainstorming" className="footer-link">Brainstorming</a></li>
    <li><a href="#online-whiteboard" className="footer-link">Online Whiteboard</a></li>
    <li><a href="#team-collaboration" className="footer-link">Team Collaboration</a></li>
  </ul>
</div>

<div className="col-lg-2 col-md-6 col-sm-6 pt-3 ps-0 text-white">
  <ul style={{ listStyle: "none" }}>
    <li className="fw-bold pb-2">Explore</li>
    <li><a href="#design" className="footer-link">Design</a></li>
    <li><a href="#prototyping" className="footer-link">Prototyping</a></li>
    <li><a href="#development-features" className="footer-link">Development Features</a></li>
    <li><a href="#design-systems" className="footer-link">Design Systems</a></li>
    <li><a href="#collaboration-features" className="footer-link">Collaboration Features</a></li>
    <li><a href="#design-process" className="footer-link">Design Process</a></li>
    <li><a href="#figjam" className="footer-link">FigJam</a></li>
  </ul>
</div>
          <div className="col-lg-4 col-sm-6 col-md-6">
            <div className="review-card pt-5 pb-5  ">
              <div className="review-title">
                <img src={logo} width={35} className="border" alt="logo" />
                <h4 className="text-white  ">NEKSOGIX REVIEWS</h4>
              </div>
              <div className="rating-container mt-3">
                <h5 className="rating-number">5.0</h5>
                <div className="stars d-flex align-items-center fs-5">
                  <i>
                    <IoStarSharp />
                  </i>
                  <i>
                    <IoStarSharp />
                  </i>
                  <i>
                    <IoStarSharp />
                  </i>
                  <i>
                    <IoStarSharp />
                  </i>
                  <i>
                    <IoStarSharp />
                  </i>
                </div>
              </div>
              <div className="rating-text pt-2">
                <p className="text-start">Scheduling</p>
                <p >{scheduling}</p>
              </div>
              <div className="rating-bar-container">
                <div
                  className="rating-bar"
                  style={{ width: `${scheduling * 20}%` }}
                ></div>
              </div>
              <div className="rating-text pt-2">
                <p>Quality</p>
                <p>{quality}</p>
              </div>
              <div className="rating-bar-container">
                <div
                  className="rating-bar"
                  style={{ width: `${quality * 20}%` }}
                ></div>
              </div>
              <div className="rating-text pt-2">
                <p>Cost</p>
                <p>{cost}</p>
              </div>
              <div className="rating-bar-container">
                <div
                  className="rating-bar"
                  style={{ width: `${cost * 20}%` }}
                ></div>
              </div>
              <div className="rating-text pt-2">
                <p>Recommended</p>
                <p>{recomend}</p>
              </div>
              <div className="rating-bar-container">
                <div
                  className="rating-bar"
                  style={{ width: `${recomend * 20}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className="copyright-bar">
            © Copyright 2024 Neksogix. All Rights Reserved
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
